<template>
    <Fragment>
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="12">
                <v-btn @click.stop="modalFormulario = true" class="primary" block><v-icon color="white">mdi-bell-cog-outline</v-icon> &nbsp &nbsp &nbsp Crear notificación</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12" md="12">
                <v-dialog v-model="modalFormulario" persistent>
                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                            <v-card>
                                <v-card-title>
                                    <h3 class="text-center">Crear notificación</h3>
                                </v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="6" md="2">
                                            <v-switch v-model="form.todos" label="Notificar a todos los perfiles" />
                                        </v-col>
                                        <v-col>
                                            <v-autocomplete v-if="!form.todos" v-model="form.perfiles" :items="perfiles"
                                                item-text="nombre" item-value="id" outlined clearable
                                                label="Seleccione los perfiles a los que notificará" multiple chips />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-textarea v-model="form.mensaje" label="Mensaje" outlined rows="3" />
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions>
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-btn class="secondary" block @click.stop="validarForm">Enviar</v-btn>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-btn block @click.stop="cerrarForm">Cerrar</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-dialog>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12" md="12">
                <ConfirmationDialog :show="modalConfirmacion" title="Deseas enviar esta notificación?"
                    message="Se notificará a los perfiles seleccionados." btnConfirmar="ENVIAR"
                    @close="modalConfirmacion = false" @confirm="enviarNotificaciones" />
            </v-col>
        </v-row>
    </Fragment>
</template>
<script>
import { Fragment } from 'vue-fragment';
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";

export default {
    name: 'EnviarNotificaciones',
    components: {
        Fragment,
        ConfirmationDialog
    },
    data: () => ({
        modalConfirmacion: false,
        modalFormulario: false,
        form: {
            perfiles: [],
            todos: false,
            mensaje: ""
        },
        perfiles: []
    }),
    methods: {
        validarForm() {
            if (!this.form.todos) {
                if (this.form.perfiles.length === 0) {
                    return this.pushAppMessage({
                        type: 'warning',
                        message: 'Seleccione al menos un perfil',
                    });
                }
            }
            if (this.form.mensaje === "") {
                return this.pushAppMessage({
                    type: 'warning',
                    message: 'Ingrese un mensaje',
                });
            }
            this.modalConfirmacion = true;
        },
        async enviarNotificaciones() {

            await this.services.Notificaciones.enviarNotificaciones(this.form);

            this.modalConfirmacion = false;

            this.cerrarForm();
        },
        async listarPerfiles() {
            const { data } = await this.services.Notificaciones.listarPerfiles();
            this.perfiles = data;
        },
        cerrarForm() {
            this.modalFormulario = false;
            this.form = {
                perfiles: [],
                todos: false,
                mensaje: ""
            };
        }
    },
    async mounted() {
        await this.listarPerfiles();
    }
}
</script>