<template>
    <Fragment>
        <v-row>
            <v-col cols="12" md="12">
                <v-btn block class="primary" @click="(confirmacion_modal = true)"><v-icon color="white">mdi-logout</v-icon>&nbsp &nbsp &nbsp CERRAR TODAS LAS SESIONES</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <ConfirmationDialog :show="confirmacion_modal" title="¿DESEAS CERRAR TODAS LAS SESIONES?"
                    message="De confirmarse, TODOS LOS USUARIOS SERÁN DESLOGUEADOS DEL SISTEMA" btnConfirmar="CONFIRMAR"
                    @close="confirmacion_modal = false" @confirm="cerrarSesiones" />
            </v-col>
        </v-row>
    </Fragment>
</template>

<script>
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";
import { Fragment } from 'vue-fragment';

export default {
    name: 'CerrarSessiones',
    components: {
        ConfirmationDialog,
        Fragment
    },
    data: () => ({
        confirmacion_modal: false,
    }),
    methods: {
        async cerrarSesiones() {
            const { data } = await this.services.Auth.cerrarSesiones();
            if (data) {
                this.temporalAlert({
                    show: true,
                    message: "Sesiones cerradas correctamente",
                    type: "success",
                });
            } else {
                this.temporalAlert({
                    show: true,
                    message: "Ocurrió un error al cerrar las sesiones",
                    type: "error",
                });
            }
            this.confirmacion_modal = false;
        },
    }
}
</script>