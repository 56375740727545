var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[(_vm.isProvider)?_c('DashboardPerfil'):_c('v-row',[_c('v-col',{staticClass:"bg-dark rounded-lg",attrs:{"cols":"12"}},[_c('v-img',{staticClass:"mt-8 mb-10 mx-auto",attrs:{"contain":"","src":require("@/assets/img/comprasal_oficial.png"),"max-width":"350"}})],1),_c('v-col',{staticClass:"text-center color-dark",attrs:{"cols":"12"}},[_c('h1',[_vm._v("Bienvenido a tu portal comprasal")])])],1),_c('v-row',[_c('v-col',[_c('adminActions')],1)],1),_c('v-row'),_c('v-row',[(_vm.haveRoles(['ROLE_DIRECCION_COMPRAS', 'ROLE_TECNICO_DIRECCION_COMPRAS', 'ROLE_UACI', 'ROLE_UACI_TECNICO', 'ROLE_OFICIAL_CUMPLIMIENTO']))?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{staticClass:"rounded-lg"},[_c('v-card-title',{staticClass:"bg-dark rounded-top-lg"},[_c('h3',{staticClass:"text-center white--text"},[_vm._v("Reporte de PAC")])]),_c('v-card-text',[_c('v-autocomplete',{staticClass:"mt-4",attrs:{"items":_vm.anios,"label":"Año","outlined":"","dense":"","item-value":"anio","item-text":"anio","hide-details":"","clearable":""},model:{value:(_vm.pacFilters.year),callback:function ($$v) {_vm.$set(_vm.pacFilters, "year", $$v)},expression:"pacFilters.year"}}),(
            _vm.haveRoles([
              'ROLE_DIRECCION_COMPRAS',
              'ROLE_TECNICO_DIRECCION_COMPRAS',
              'ROLE_UACI'
            ])
          )?_c('v-autocomplete',{staticClass:"mt-4",attrs:{"items":_vm.instituciones,"item-text":"nombre","item-value":"id","label":"Institución","outlined":"","dense":"","hide-details":"","return-object":"","clearable":""},model:{value:(_vm.pacFilters.institucion),callback:function ($$v) {_vm.$set(_vm.pacFilters, "institucion", $$v)},expression:"pacFilters.institucion"}}):_vm._e(),_c('v-checkbox',{staticClass:"my-4",attrs:{"label":"Reporte público","color":"primary","hide-details":""},model:{value:(_vm.pacFilters.publico),callback:function ($$v) {_vm.$set(_vm.pacFilters, "publico", $$v)},expression:"pacFilters.publico"}}),_c('v-btn',{staticClass:"mt-4 mb-2",attrs:{"disabled":!_vm.activarBotonDescargarPac,"color":"primary"},on:{"click":function($event){return _vm.downloadPac()}}},[_vm._v(" Generar reporte ")])],1)],1)],1):_vm._e()],1),_c('ConfirmationDialog',{attrs:{"show":_vm.modalConfirmacionOrden,"title":"¿DESEAS GENERAR LAS ORDENES DE COMPRA?","message":"Se crearan todas las ordenes de compra pendientes.","btnConfirmar":"CONFIRMAR","showLoading":_vm.ordenesCompra.isLoading},on:{"close":function($event){_vm.modalConfirmacionOrden = false},"confirm":_vm.crearOrdenCompra}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }