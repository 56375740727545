<template>
  <Fragment>
    <v-row>
      <v-col cols="6" md="6" v-if="haveRoles(['ROLE_SUPER_ADMIN'])">
        <cerrarSessionesVue/>
      </v-col>
      <v-col
        cols="6"
        md="6"
        v-if="haveRoles(['ROLE_SUPER_ADMIN', 'ROLE_NOTIFICACIONES_CREAR'])">
        <enviarNotificacionesVue/>
      </v-col>
    </v-row>
  </Fragment>
</template>
<script>
import { Fragment } from "vue-fragment";
import cerrarSessionesVue from "./cerrarSessiones.vue";
import enviarNotificacionesVue from "./enviarNotificaciones.vue";
export default {
  name: "AdminActions",
  components: {
    cerrarSessionesVue,
    Fragment,
    enviarNotificacionesVue,
  },
};
</script>
